
import React from "react"
import ImgInvitatie from "./ImgInvitatie";
import mikey from '../images/panda-logo.png'
import "../css/ImgInvitatie.css"
import { WhatsApp } from "@mui/icons-material";
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import MessageIcon from '@mui/icons-material/Message';


const CardInvitatie = () => {
    return (

        <section id="invitatie" className="text-center mt-5" >
            <h1>Пригласительная</h1>
            <div className="card card-invitation text-center col-lg-6 col-sm-10  m-auto mb-4">
                <div className="card p-0 overflow-hidden h-100 shadow">


                    <div class="card-body">
                        <div className="text">

                            <h5 className="animate__animated animate__pulse animate__delay-4s animate__infinite">
                                <svg width="1.5em" height="1.5em" color="red" viewBox="0 0 16 16" class="bi bi-suit-heart-fill"
                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
                                </svg>
                                <br />
                            </h5>

                            <h2>
                            Семья  Падуреану <br/>
                              Андрей и Ангелина <br/>
                              приглашают вас <br/>
                              на крещение детей! <br/>
                               

                            </h2>

                            <img id="mikey_img" src={mikey} />

                            <h1 class="m-2" style={{color: 'white'}}>Среда, 19 Апреля 2023</h1>
                        </div>



                        <ImgInvitatie />






                        <h1 class="card-footer" style={{color: 'white'}}>Ждем Вас!</h1>
<h3>  Будем признательны, если вы подтвердите свое присутствие. Пожалуйста, свяжитесь с нами или напишите нам сообщение:</h3>

  <a href="#forms" className="btn btn-primary">свяжитесь с нами</a>



                    </div>




                </div>




            </div>


        </section>

    )
}

export default CardInvitatie;